import { PageTypes, Theme, ThemeContext } from "assets";
import { Header } from "atoms";
import { graphql } from "gatsby";
import {
  convertBreadcrumbNavigation,
  convertChronoNavigation,
  convertDirectionNavigation,
  convertHero,
} from "molecules";
import moment from "moment";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const ChronoEntry = ({ data }) => {
  const chronoEntryData = data?.craftAPI?.entry;

  chronoEntryData.heroType = chronoEntryData.parent?.heroType;

  chronoEntryData.tags = [];
  chronoEntryData.parent.topicTag?.map((tag) => {
    chronoEntryData.tags.push(tag.title);
  });

  const children = convertComponentList(chronoEntryData?.componentList);

  let year;
  if (chronoEntryData.startDate) {
    if (moment().isSameOrBefore(chronoEntryData.startDate, "year"))
      year = "Today";
    else year = moment(chronoEntryData.startDate).format("YYYY");
  }

  children.unshift(
    <div className="container-fluid">
      <Header
        className={"presentToPast"}
        key="header"
        subtitle={[year, chronoEntryData.subtitle].filter((s) => s).join("-")}
        title={chronoEntryData.title}
      />
    </div>
  );

  children.unshift(convertChronoNavigation(chronoEntryData));
  // Place the hero at the top of the page
  children.unshift(convertHero(chronoEntryData));
  children.unshift(
    convertBreadcrumbNavigation({
      pageTitle: chronoEntryData.title,
      parents: chronoEntryData.parent.parent,
    })
  );
  children.push(convertDirectionNavigation(chronoEntryData?.parent));

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Dark,
        fontType: "",
      }}
    >
      <PageLayout
        coverImage={chronoEntryData.coverImage}
        pageType={PageTypes.STORY}
        shortDescription={chronoEntryData.shortDescription}
        theme={Theme.Dark}
        title={chronoEntryData.title}
      >
        {children}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

export const chronoEntryQuery = graphql`
  query (
    $uri: [String]
    $section: [String] = "story"
    $type: [String] = ["ptpTopic", "story", "storiesLanding"]
  ) {
    craftAPI {
      entry(uri: $uri) {
        ... on CraftAPI_story_ptpEntry_Entry {
          id
          componentList {
            ...ComponentListFragment
          }
          ...ChronoNavigationFragment
          startDate
          subtitle
          title
          shortDescription
          coverImage {
            ...ImageMetadataFragment
          }
          parent {
            ... on CraftAPI_story_ptpTopic_Entry {
              ...BreadcrumbNavigationFragment
              id
              coverImageCropStyle
              coverImagePositionOverride
              coverImage {
                ...ImageMetadataFragment
              }
              longDescription
              shortDescription
              storyType(label: true)
              topicTag {
                title
              }
              heroType
              ...TypedDirectionNavigationFragment
            }
          }
        }
      }
    }
  }
`;

ChronoEntry.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({}),
    }),
  }),
};

export default ChronoEntry;
